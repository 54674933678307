/* Dark theme styling */
body {
  background-color: #0b1a33; /* Very dark navy blue */
  color: #e0e0e0; /* Light text */
  font-family: Arial, sans-serif;
}

.app {
  padding: 20px;
}

.header {
  text-align: center;
  color: #ffffff;
}

.standings-list {
  list-style-type: none;
  padding: 0;
}

.standings-item {
  margin-bottom: 10px;
  background-color: #172b4d;
  padding: 10px;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s ease;
}

.standings-item:hover {
  background-color: #1c375c;
}

.friend-name {
  cursor: pointer;
  margin: 0;
}

.friend-name:hover {
  text-decoration: underline;
}

.team-list {
  margin-top: 10px;
  list-style-type: none;
  padding-left: 20px;
}

.team-item {
  padding: 5px 0;
  display: flex;
  align-items: center; /* Align logos and text vertically */
}

.team-logo {
  width: 20px; /* Set a fixed width for the logo */
  height: 20px; /* Ensure the height matches the text */
  margin-right: 10px; /* Add some space between the logo and the text */
}

/* Smooth collapsing/expanding animation */
ul.team-list {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.refresh-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1000; /* Ensure it's on top of other content */
}

.refresh-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.refresh-btn:hover:not(:disabled) {
  background-color: #45a049;
}
