/* Full Navbar container */
.navbar-container {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  margin: 20px 0; /* Adds spacing around the navbar */
  gap: 20px; /* Adds space between the buttons */
}

/* Styling for the buttons */
.nav-button {
  background-color: #4c6a92; /* Navy-blue background */
  color: #ffffff; /* White text */
  padding: 10px 20px; /* Button padding */
  border-radius: 25px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold text */
  font-size: 1em; /* Font size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for buttons */
.nav-button:hover {
  background-color: #2e4a6b; /* Darker navy-blue when hovered */
}
